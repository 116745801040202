.host {
  display: block;
}
img {
    width: 50%;
   height: auto;
  }
.carousel-inner {
    max-width:1000px;
    width: 100%;
    height: auto;
}
